import queryKeys from '../../utils/url/queryKeys';
import { ActionTypes } from '../constants/actionTypes/bookingstart';
import { getQueryKeyValue } from '../../utils/url/queryHelper';

const flightReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.FLIGHT_INIT_SECTION:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.FLIGHT_INIT_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        departureAirportList: action.data.departures,
        countryList: action.data.countries,
        destinationAirportList: action.data.destinationAirports,
        durationList: action.data.durations,
        departureDates: action.data.departureDates,
        returnDates: action.data.returnDates,
        selectedDepartureAirport: action.data.selectedDeparture,
        selectedDestinationAirport: action.data.selectedDestinationAirport,
        selectedDuration: action.data.selectedDuration,
        selectedDepartureDate: action.data.selectedDepartureDate,
        selectedReturnDate: action.data.selectedReturnDate,
        selectedPassengerCombination: action.data.selectedPassengerCombination,
        oneWaySearchUrl: action.data.oneWaySearchUrl,
        searchHistory: action.data.searchHistory,
        searchInformation: action.data.searchInformation,
        sectionName: 'flightOnly',
        showDirectOnly: false,
        searchDirectOnly: false,
        showLuggageIncluded: false,
        searchLuggageIncluded: false,
        showCabinClasses: null,
        selectedCabinClass: null,
      };

    case ActionTypes.FLIGHT_CHANGE_DEPARTURE_AIRPORT:
      return {
        ...state,
        selectedDepartureAirport: action.airport,
      };

    case ActionTypes.FLIGHT_SEARCH_DESTINATIONS_SUCCESS:
      return {
        ...state,
        searchResultsDestination: action.searchResults,
      };

    case ActionTypes.FLIGHT_GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countryList: action.countries,
        selectedDestinationAirport: action.destination,
      };

    case ActionTypes.FLIGHT_CHANGE_DESTINATION:
      return {
        ...state,
        selectedDestinationAirport: action.destination,
      };

    case ActionTypes.FLIGHT_GET_DURATIONS_SUCCESS:
      return {
        ...state,
        durationList: action.durations,
        selectedDuration: action.duration,
      };

    case ActionTypes.FLIGHT_CHANGE_DURATION:
      return {
        ...state,
        selectedDuration: action.duration,
      };

    case ActionTypes.FLIGHT_GET_DEPARTURE_DATES_SUCCESS:
      return {
        ...state,
        departureDates: action.departureDates,
        selectedDepartureDate: action.departureDate,
        selectedReturnDate: action.returnDate,
      };

    case ActionTypes.FLIGHT_CHANGE_DEPARTURE_DATE:
      return {
        ...state,
        selectedDepartureDate: action.date,
        selectedReturnDate: action.returnDate,
      };

    case ActionTypes.FLIGHT_CHANGE_DATES:
      return {
        ...state,
        selectedDepartureDate: action.departureDate,
        selectedReturnDate: action.returnDate,
      };

    case ActionTypes.FLIGHT_CHANGE_PASSENGER_COMBINATION:
      return {
        ...state,
        selectedPassengerCombination: {
          ...state.selectedPassengerCombination,
          numberOfAdults: action.nOfAdults,
          numberOfChildren: action.nOfChildren,
          childAges: action.childAges,
        },
      };

    case ActionTypes.FLIGHT_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS: {
      const { options } = action;
      const cabinClassFromUrl = getQueryKeyValue(queryKeys.BOOKING_START.cabinClass);

      const cabinClassAllowed =
        options?.displayCabinClasses?.constructor === Array &&
        options?.displayCabinClasses?.includes(cabinClassFromUrl);

      const selectedCabinClass =
        (cabinClassAllowed && cabinClassFromUrl) || options?.selectedCabinClass;
      return {
        ...state,
        showDirectOnly: options?.displayDirectOnly,
        showLuggageIncluded: options?.displayBaggage,
        searchLuggageIncluded: options?.displayBaggage === true,
        showCabinClasses: options?.displayCabinClasses,
        selectedCabinClass,
      };
    }

    case ActionTypes.FLIGHT_SET_CABINCLASS:
      return {
        ...state,
        selectedCabinClass: action.cabinclass,
      };

    case ActionTypes.FLIGHT_SET_SEARCH_DIRECT_ONLY:
      return {
        ...state,
        searchDirectOnly: action.searchDirectOnly,
      };

    case ActionTypes.FLIGHT_SET_SEARCH_LUGGAGE_INCLUDED:
      return {
        ...state,
        searchLuggageIncluded: action.searchLuggageIncluded,
      };

    default:
      return state;
  }
};

export default flightReducer;
